

#img {
  width:25%;
  height: 25%;
}


#fixed-div {
  position: absolute;
  top: 1em;
  left: 1em;
  z-index: 1000;
}

#fixed-div-PurpleButton {
  position: absolute;
  top: 25vh;
  left: 2em;
  z-index: 1000;
}


