.Center-Login-Title{
        margin: auto;
        text-align: center;
        color: white;
        font-family: 'Luckiest Guy';
        font-size: 2vw;
      
}

.Center-Image-Login{
  display: block;
  margin-top: 10vw;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.Input-Container-1-Purple-Box{
background-color: rgb(192, 98, 167);
background-image: url('../Assets/loginBG.png');
width: 50%;
margin: auto;
border-radius: 50px;
}



.fixed-div {
  position: absolute;
  top: 0;
  left:0;
  display: block;
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  
  z-index: 1000;
}



@import url(https://fonts.googleapis.com/css?family=Luckiest+Guy&display=swap);
.input-email[type=email], select {
    width: 75%;
    padding: 2vw;
    margin-top: 5vh;
    margin-bottom: 1vw;
    margin-right: auto;
    margin-left: auto;
    display: block;
    border: 1px solid #ccc;
    border-radius: 50px;
    box-sizing: border-box;
    font-size: 18px;
  }
.input-password[type=password], select {
    width: 75%;
    padding: 2vw;
    margin-top: 1%;
    margin-bottom: 1vw;
    margin-right: auto;
    margin-left: auto;
    display: block;
    border: 1px solid #ccc;
    border-radius: 50px;
    box-sizing: border-box;
    font-size: 18px;
  }
.button-submit[type=submit] {
    width: 50%;
    font-family: 'Luckiest Guy';
    padding: 1vw;
    margin-top: 1vh;
    margin-bottom: 1vh;
    margin-right: auto;
    margin-left: auto;
    background-color: rgb(192, 98, 167);
    background-image: url('../Assets/loginbuttonBG.png');
    color: white;
    display: block;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    font-size: 3vw;
      
  }
  
.button-submit[type=submit]:hover {
    background-color: rgb(198, 215, 71);
  }

.pupil-mobile-image {
  display: none
}

.pupil-desktop-image {
  display: block
}
  
  
@media only screen and (max-width: 600px) {
  .pupil-mobile-image {
    display: block
  }
  
  .pupil-desktop-image {
    display: none
  }
}

  
@media only screen and (max-width: 600px) {
  @import url(https://fonts.googleapis.com/css?family=Luckiest+Guy&display=swap);
  .input-email[type=email], select {
      width: 50%;
      padding: 2vw;
      margin-top: 5vh;
      margin-bottom: 5vh;
      margin-right: auto;
      margin-left: auto;
      display: block;
      border: 1px solid #ccc;
      border-radius: 50px;
      box-sizing: border-box;
      font-size: 18px;
    }
  .input-password[type=password], select {
      width: 50%;
      padding: 2vw;
      margin-top: 1%;
      margin-bottom: 5vh;
      margin-right: auto;
      margin-left: auto;
      display: block;
      border: 1px solid #ccc;
      border-radius: 50px;
      box-sizing: border-box;
      font-size: 18px;
    }
  .button-submit[type=submit] {
      width: 40%;
      font-family: 'Luckiest Guy';
      padding: 1vw;
      margin-top: 1vh;
      margin-bottom: 5vh;
      margin-right: auto;
      margin-left: auto;
      background-color: rgb(192, 98, 167);
      background-image: url('../Assets/loginbuttonBG.png');
      color: white;
      display: block;
      border: none;
      border-radius: 50px;
      cursor: pointer;
      font-size: 8vw;
        
    }
    .Input-Container-1-Purple-Box{
      background-color: rgb(192, 98, 167);
      background-image: url('../Assets/loginBG.png');
      width: 10%;
      margin: auto;
      border-radius: 50px;
      }

      .Center-Login-Title{
        margin: auto;
        text-align: center;
        color: white;
        font-family: 'Luckiest Guy';
        font-size: 5vw;
        padding-bottom: 5vw;
      
}
      
}

