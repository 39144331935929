@import url(https://fonts.googleapis.com/css?family=Luckiest+Guy&display=swap);
.Verify-Email-Box {
    position: fixed;
    top: 50%;
    left: 50%;
    background-color: rgb(192, 98, 167) ;
    width: 50vw;
    padding: 1vw;
    margin: auto;
    font-family: 'Luckiest Guy';
    color: white;
    transform: translate(-50%, -50%);
    font-size: 2vw;
    border-radius: 50px;
}

.button-resend {
    width: 30%;
    font-family: 'Luckiest Guy';
    padding: 1vw;
    margin-top: 1vh;
    margin-bottom: 5%;
    margin-right: auto;
    margin-left: auto;
    background-color: rgb(245, 214, 61);
    background-image: url('../Assets/loginbuttonBG.png');
    color: white;
    display: block;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    font-size: 2vw;
      
    }
    
    .button-resend:hover {
    background-color: rgb(242, 140, 51);
    }