* {
  box-sizing: border-box;
}

.delete-button{

  color: green;
}


.images-horizontal-centered-row {
  float: left;
  width: 100%;
  height:auto;
  padding: 5px;
  margin:auto;
  
  
}

#img {
  width:25%;
  height: 25%;
}


#fixed-div {
  position: absolute;
  top: 1em;
  left: 1em;
  z-index: 1000;
}

.Gallery-BG {
    display: 'block';
    width: 100vw;
    height: 50vh;
    padding:0 ;
    margin: 0;
    
}



.Gallery-BG-1 {
  background-image: url('./Assets/GalleryStarBG-RESIZE.gif');
  background-repeat: repeat-y;
  width: 100vw;
  height: 100vh;
  background-size: 100vw 50vh;
  padding:0 ;
  margin: 0;
  
  
}


.Gallery-BG-2 {
  background-image: url('./Assets/alium.gif');
  background-repeat: repeat-y;
  width: 100vw;
  height: 100vh;
  background-size: 100vw 50vh;
  padding:0 ;
  margin: 0;
}

.Gallery-BG-3 {
  background-image: url('./Assets/GalleryGlitchBG.gif');
  background-repeat: repeat-y;
  width: 100vw;
  height: 100vh;
  background-size: 100vw 50vh;
  padding:0 ;
  margin: 0;
  
}

.Gallery-BG-4 {
  background-image: url('./Assets/kalidal.gif');
  background-repeat: repeat-y;
  width: 100vw;
  height: 100vh;
  background-size: 100vw 50vh;
  padding:0 ;
  margin: 0;
  
}

.lazy-images {
  width:35vw;
  height:auto;
  margin-left: 20vw;
  margin-right: 20vw;
  margin-bottom:2vh;
  margin-top:2vh;
}

.loader-comment-image{
  width:10vw;
  height:auto;
  margin-right: 25vw;


}

.Comment-Box {
 
  flex: 1;
  width: auto;

  height: 78vh;
  margin:5px;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  z-index:1001;

  flex: 100vw;


}

.center-profile-pic-name{
  font-family: 'Luckiest Guy';
  text-align: center;
  margin:auto
}




.flex-container {
  display: flex;
  width: 94%;
  height:100%;
  background-color: white;
  border-radius: 25px;
  margin-right:auto;
  margin-left:auto;
  margin-top: 2em;
  
  
}

.flex-child {
  flex: 1;
  font-size: 2vw;
  /* MOBILE FONT SIZE 10vw */
  width: 25vw;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  font-family: 'Luckiest Guy';
  margin: auto;
  padding:auto;
  color:rgb(120, 197, 214);




}  

.flex-child:first-child {
  margin: auto;
  color:rgb(69, 155, 168);
  font-size: 2.5vw;
} 




.Comment-Img-Profile {
  
  width: 15vw;
  height: 15vh;
  padding:10px;
  margin:10px;
  border-radius: 50%;
  
}

section {
  display: block;
  align-items: center;
  justify-content: center;

}




@media (max-width: 480px) {

  #parent {
    display: flex;
    flex-direction: column;
    height:80vh;
  }

  .Comment-Img-Profile {
  
    width: 30vw;
    height: 17vh;
    border-radius: 50%;
    margin-left:-2vw;
  }

  .comment-username{

    
    font-size: 3vw;

  }

  

  .Gallery-BG-4 {
    background-image: url('./Assets/kalidal-MOBILE.gif');
  }

  .Gallery-BG-2 {
    background-image: url('./Assets/alium-MOBILE.gif');
  }
  .Gallery-BG-1 {
  background-image: url('./Assets/GalleryStarBG-RESIZE-MOBILE.gif');
  }

  .lazy-images {
    width:50vw;
    height:auto;
    margin-left: 20vw;
    margin-bottom:2vh;
    margin-top:2vh;
    pointer-events: none;
  }
}

#parent {
  display: flex;
  
  

  
}

#parent div {
  flex-basis: 100%;
  

}


#narrow {
flex: 1;
 width:50vw;
 max-height: calc(100vh - 70vh) !important;
 margin-left:1vw;
 margin-right:1vw;

 flex-shrink: 1;

 
 
  /* Just so it's visible */
 
  
}

/* width */
::-webkit-scrollbar {
  width: 0;
  height:0;
 
}

#wide {
  
  /* Grow to rest of container */

  width:100vw;
  flex-shrink: 1;
  
 
 
  /* Just so it's visible */
}


.IMG-Viewer-Comment-Overlay-Container{
  width: 100%;
  height: 100%;

}

.Comment-zIndex{
  position: fixed;
  z-index: 10;
  bottom:1%;
  z-index:1001;
  
}

@import url(https://fonts.googleapis.com/css?family=Luckiest+Guy&display=swap);


.IMGComment-zIndex{
  position: fixed;
  width: auto;
  height: auto;
  bottom:1%;
  right: .5vw;
  font-family: 'Luckiest Guy';
  border-radius: 50px;
  color: white;
  background: rgb(192,98,167);

  font-size: 4vw;
  z-index:1001;
  
}



/* COMMENT USER TEXT INPUT OVERLAY*/

.Comment-Text-Overlay-Container {
position: fixed;
display:block;
width: 100%; /* Full width (cover the whole page) */
height: 100%; /* Full height (cover the whole page) */
top: 0;
left: 0;
right: 0;
bottom: 0;
background: rgb(255,255,255);
background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(120,197,214,1) 17%, rgba(232,104,162,1) 17%, rgba(242,140,51,1) 43%, rgba(245,214,61,1) 43%, rgba(121,194,103,1) 70%, rgba(198,215,71,1) 70%, rgba(192,98,167,1) 96%);
z-index:20000;

}

.IMGPost-zIndex-container{
  position: fixed;
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 5vh;
  left: 0;
  right: 0;
  bottom: 0;

 
  
}



.IMGPost-zIndex{
  display:block;
  width: 50%;
  height: auto;
  z-index: 1001;
  margin:auto;
  font-family: 'Luckiest Guy';
  border-radius: 50px;
  color: white;
  background-color: rgb(192, 98, 167) ;
  font-size: 4vw;
 
  
}



.Text-Comment-Box {
  display:block;
  width: auto;
  height: 35vw;
  margin: auto;
  font-size: 5vw;
  font-family: 'Luckiest Guy';
  border: 3px solid rgb(0, 255, 0);

  
}



.Open-Comment-button-size {

  width: auto; 
  height:auto;
  margin:.5vw;
  border-radius:50px;
  background-color: rgb(192, 98, 167);
  cursor: pointer;
} 

@media only screen and (max-width: 700px) {

  


  .loader-comment-image{
    width:10vw;
    height:auto;
   
  
  
  }

  #parent div {
    flex-basis: 100vh;
    
  
  }

  #narrow {
    flex: 1;
   width:50vw;
 
   max-height: calc(100vh - 70vh) !important;
   flex-shrink: 1;
  }
  
  
  #wide {
    width:100vw;
    height:10vh;
    flex-shrink: 3;
    
    }

  .flex-child {

    font-size: 5vw;
  } 
  
  .flex-child:first-child {

    font-size: 4vw;
    margin-bottom: 2em;
  } 

  .Comment-Box {
 
    flex: 2;
    width: auto;
    max-height: calc(100vh - 71.2vh) !important; 
    height: 100vh;
    margin-bottom: 1em;
    margin-top: 1em;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    z-index:1001;
    
  flex: 51vh; 
  
  }

  .IMGComment-zIndex{
  position: fixed;
  width: auto;
  height: auto;
  z-index: 10000;
  bottom:1%;
  right: 18px;
  font-family: 'Luckiest Guy';
  border-radius: 50px;
  color: white;
  background-color: rgb(232, 104, 162);
  font-size: 8vw;
  text-align: center;
  
}

  
.Open-Comment-button-size {
  position: fixed;
  width: auto; 
  height:auto;
  font-size: 7vw;
  border-radius: 15px;
  background-color: rgb(232, 104, 162) ;
  bottom:1%;
  left: 18px;

 
} 

.backdrop .Mainimg {
  display: block;
  width:90vw;
  height:auto;
  margin-left:20px;
  margin-top:20px;
  box-shadow:10px 10px 350px rgb(255, 255, 255) ;
  background: rgba(255, 255, 255, 0.5) ;
  border-radius: 25px;


 
}


  

}

@media (min-width: 481px) and (max-width: 767px) {


  .Comment-Img-Profile {
  
    width: 20vw;
    height: 15vh;
    padding:10px;
    margin:10px;
    border-radius: 50%;
    
  }

  .IMGComment-zIndex{
    position: fixed;
    width: auto;
    height: 15vh;
    bottom:1%;
    right: 1px;
    font-family: 'Luckiest Guy';
    border-radius: 50px;
    color: white;
    background-color: rgb(232, 104, 162);
    font-size: 5vw;
    z-index:1001;
    margin:1vw;
    
  }

 

  #parent {
    display: flex;
    height:80vh;
  }

  #narrow {
  flex: 1;
   width:100%;
 
   max-height: calc(100vh - 70vh) !important;
   flex-shrink: 1;
  }

  #wide {
    width:100vw;
    height:10vh;
    flex-shrink: 3;
    margin:auto;
    margin-bottom:30vw;

    
    }
  

 .Comment-Box {
 
    flex: 2;
    width: 25vw;
    max-height: calc(100vh - 24vh) !important; 
    height: 100vh;
    margin-bottom: 1em;
    margin-top: 1em;
    margin-right:1vw;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    z-index:1001;
    flex: 51vh; 
  
  }

  
.Open-Comment-button-size {

  width: auto; 
  height:auto;
  margin:1vw;
  font-size: 6vw;
  border-radius: 15px;
  background-color: rgb(232, 104, 162);
  

} 

.flex-container {
  display: flex;
  width: 100%;
  height:100%;
  background-color: rgb(255, 255, 255);
  border-radius: 25px;
  margin-right:10em;
  margin-top: 2em;
}

.backdrop .Mainimg {
  display: block;
 width:50vw;
 height:50vh;
  margin:auto;
  box-shadow:10px 10px 350px rgb(255, 255, 255) ;
  background: rgba(255, 255, 255, 0.5) ;
  border-radius: 25px;
  margin-top:-210px

 
}

.flex-child {
  flex: 1;
  font-size: 5vh;
  /* MOBILE FONT SIZE 10vw */
  width: 25vw;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  font-family: 'Luckiest Guy';
  margin: auto;
  padding:auto;
  color:rgb(120, 197, 214);




}  

.Delete-Confirmation-pop-up-box {

  position: fixed;
  width: 35vw;
  height: 25vh;
  top: 50%;
  left: 50%;
  margin-top: -10vh; /* Negative half of height. */
  margin-left: -15vw; /* Negative half of width. */
  background-color:rgb(121, 194, 103); 
  box-shadow: 10px 10px 350px rgb(198, 215, 71) ;
  z-index: 100001;
  border-radius: 50px;
}

.Delete-Confirmation-pop-up-box-text {  
  display: block;
  color: white;
  font-family: 'Luckiest Guy';
  text-align: center;
  font-size: 3vw;
  margin-left: 1vw;
  margin-right:1vw;
  margin-top:1vh;
  
}

.Delete-Confirmation-pop-up-box-buttons {  
  display: block;
  font-family: 'Luckiest Guy';
  text-align: center;
  margin-left: 1vw;
  margin-right:1vw;
  margin-top:4vh;

  
  
}

.Delete-Confirmation-pop-up-box-buttons-colors {  
  font-family: 'Luckiest Guy';
  text-align: center;
  font-size: 3vw;
  text-decoration: none;
  background-color:rgb(192, 98, 167); 
  color: white;
  border: none;
  margin-left: .25em;
  margin-right:.25em;
  margin-top:.12em;
 }

 .Delete-ERROR-pop-up-box {

  position: fixed;
  width: 35vw;
  height: 25vh;
  top: 50%;
  left: 50%;
  margin-top: -10vh; /* Negative half of height. */
  margin-left: -15vw; /* Negative half of width. */
  background-color:rgb(242, 140, 51) ; 
  box-shadow: 10px 10px 350px rgb(245, 214, 61) ;
  z-index: 100001;
  border-radius: 50px;
}

.Delete-ERROR-pop-up-box-text {  
  display: block;
  color: white;
  font-family: 'Luckiest Guy';
  text-align: center;
  font-size: 3vw;
  margin-left: 1vw;
  margin-right:1vw;
  margin-top:1vh;
  
}

.Delete-ERROR-pop-up-box-buttons {  
  display: block;
  font-family: 'Luckiest Guy';
  text-align: center;
  margin-left: 1vw;
  margin-right:1vw;
  margin-top:4vh;

  
  
}

.Delete-ERROR-pop-up-box-buttons-colors {  
  font-family: 'Luckiest Guy';
  text-align: center;
  font-size: 3vw;
  text-decoration: none;
  background-color:rgb(198, 215, 71) ;
  color: white;
  border: none;
  margin-left: .25em;
  margin-right:.25em;
  margin-top:10em;
 }


}



.Delete-Confirmation-pop-up-box {

  position: fixed;
  width: 35vw;
  height: 38vh;
  top: 50%;
  left: 50%;
  margin-top: -20vh; /* Negative half of height. */
  margin-left: -18vw; /* Negative half of width. */
  background-color:rgb(121, 194, 103); 
  box-shadow: 10px 10px 350px rgb(198, 215, 71) ;
  z-index: 100001;
  border-radius: 50px;
}

.Delete-Confirmation-pop-up-box-text {  
  display: block;
  color: white;
  font-family: 'Luckiest Guy';
  text-align: center;
  font-size: 3vw;
  margin-left: 1vw;
  margin-right:1vw;
  margin-top:1vh;
  
}

.Delete-Confirmation-pop-up-box-buttons {  
  display: block;
  font-family: 'Luckiest Guy';
  text-align: center;
  margin-left: 1vw;
  margin-right:1vw;
  margin-top:2vh;


  
  
}

.Delete-Confirmation-pop-up-box-buttons-colors {  
  font-family: 'Luckiest Guy';
  text-align: center;
  font-size: 3vw;
  text-decoration: none;
  background-color:rgb(192, 98, 167); 
  color: white;
  border: none;
  border-radius: 50px;
  margin-left: .25em;
  margin-right:.25em;
  margin-top:.12em;
 }

 .Delete-ERROR-pop-up-box {

  position: fixed;
  width: 35vw;
  height: 38vh;
  top: 50%;
  left: 50%;
  margin-top: -20vh; /* Negative half of height. */
  margin-left: -18vw; /* Negative half of width. */
  background-color:rgb(242, 140, 51) ; 
  box-shadow: 10px 10px 350px rgb(245, 214, 61) ;
  z-index: 100001;
  border-radius: 50px;
}

.Delete-ERROR-pop-up-box-text {  
  display: block;
  color: white;
  font-family: 'Luckiest Guy';
  text-align: center;
  font-size: 4vw;
  margin-left: 1vw;
  margin-right:1vw;
  margin-top:1vh;
  
}

.Delete-ERROR-pop-up-box-buttons {  
  display: block;
  font-family: 'Luckiest Guy';
  text-align: center;
  margin-left: 1vw;
  margin-right:1vw;
  margin-top:5vh;

  
  
}

.Delete-ERROR-pop-up-box-buttons-colors {  
  font-family: 'Luckiest Guy';
  text-align: center;
  font-size: 4vw;
  text-decoration: none;
  background-color:rgb(198, 215, 71) ; 
  color: white;
  border: none;
  border-radius: 50px;
  margin-left: .25em;
  margin-right:.25em;
  margin-top:.12em;
 }

 @media (max-width: 480px) {

  .Delete-Confirmation-pop-up-box {

    position: fixed;
    width: 70vw;
    height: 25vh;
    top: 50%;
    left: 50%;
    margin-top: -7vh; /* Negative half of height. */
    margin-left: -35vw; /* Negative half of width. */
    background-color:rgb(121, 194, 103); 
    box-shadow: 10px 10px 350px rgb(198, 215, 71) ;
    z-index: 100001;
    border-radius: 50px;
  }
  
  .Delete-Confirmation-pop-up-box-text {  
    display: block;
    color: white;
    font-family: 'Luckiest Guy';
    text-align: center;
    font-size: 8vw;
    margin-left: 1vw;
    margin-right:1vw;
    margin-top:3vh;
    
  }
  
  .Delete-Confirmation-pop-up-box-buttons {  
    display: block;
    font-family: 'Luckiest Guy';
    text-align: center;
    margin-left: 1vw;
    margin-right:1vw;
    margin-top:.25vh;
    
  
    
    
  }
  
  .Delete-Confirmation-pop-up-box-buttons-colors {  
    font-family: 'Luckiest Guy';
    text-align: center;
    font-size: 8vw;
    text-decoration: none;
    background-color:rgb(192, 98, 167); 
    color: white;
    border: none;
    margin-left: .25em;
    margin-right:.25em;
    margin-top:.12em;
   }
  
   .Delete-ERROR-pop-up-box {

    position: fixed;
    width: 70vw;
    height: 25vh;
    top: 50%;
    left: 50%;
    margin-top: -7vh; /* Negative half of height. */
    margin-left: -35vw; /* Negative half of width. */
    background-color:rgb(242, 140, 51) ; 
    box-shadow: 10px 10px 350px rgb(245, 214, 61) ;
    z-index: 100001;
    border-radius: 50px;
  }
  
  .Delete-ERROR-pop-up-box-text {  
    display: block;
    color: white;
    font-family: 'Luckiest Guy';
    text-align: center;
    font-size: 8vw;
    margin-left: 1vw;
    margin-right:1vw;
    margin-top:3vh;
    
  }
  
  .Delete-ERROR-pop-up-box-buttons {  
    display: block;
    font-family: 'Luckiest Guy';
    text-align: center;
    margin-left: 1vw;
    margin-right:1vw;
    margin-top:.25vh;
    
  
    
    
  }
  
  .Delete-ERROR-pop-up-box-buttons-colors {  
    font-family: 'Luckiest Guy';
    text-align: center;
    font-size: 8vw;
    text-decoration: none;
    background-color:rgb(198, 215, 71) ; 
    color: white;
    border: none;
    margin-left: .25em;
    margin-right:.25em;
    margin-top:.12em;
   }
  
.login-Button-Style {
  font-family: 'Luckiest Guy';
  text-align: center;
  color: white;
  background-color:rgb(198, 215, 71) ; 


}

 }

 
 @media (min-width: 481px) and (max-width: 812px) {

.backdrop .Mainimg {
  display: block;
 width:50vw;
 height:50vh;
  margin:auto;
  box-shadow:10px 10px 350px rgb(255, 255, 255) ;
  background: rgba(255, 255, 255, 0.5) ;
  border-radius: 25px;
  margin-top:-20px

 
}
 }