

body {
  margin: 0;
 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



.img-frame{
  width: 20rem;
  height: 20rem;

}

.progress-bar{
  height: 50px;
  background: rgb(192,98,167);
  background: linear-gradient(0deg, rgba(192,98,167,1) 13%, rgba(232,104,162,1) 51%, rgba(255,255,255,1) 83%, rgba(232,104,162,1) 97%);
  margin-top: 1%;
  z-index: 1000001;

}


/* DELETION GRID */
.img-grid{
  margin: auto;
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 1px;
  
}


.Profile-img-grid{

  margin: auto;
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 1px;


}


/* DELETION GRID */
.frame-wrap{
  overflow: hidden;
  height: 50%;
  width: auto;
  padding:  20vh;
  margin-right: 5vh;
  position: relative;
  opacity: 1;
}

/* modal style */
.backdrop{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index:100;
  background: rgb(242,140,51);
  background: radial-gradient(circle, rgba(242,140,51,1) 13%, rgba(255,255,255,1) 14%, rgba(245,214,61,1) 26%, rgba(255,255,255,1) 27%, rgba(198,215,71,1) 36%, rgba(255,255,255,1) 37%, rgba(121,194,103,1) 48%, rgba(255,255,255,1) 49%, rgba(120,197,214,1) 59%, rgba(255,255,255,1) 61%, rgba(69,155,168,1) 70%, rgba(255,255,255,1) 72%, rgba(192,98,167,1) 79%, rgba(255,255,255,1) 80%, rgba(232,104,162,1) 87%);
  z-index:1001;
 
}
.backdrop .Mainimg {
  display: block;
  max-width:80%;
  max-height: 60%;
  margin:40px auto;
  animation: colorchange 1s linear 2s infinite alternate;
  box-shadow:10px 10px 350px rgb(255, 255, 255) ;
  background: rgba(255, 255, 255, 0.5) ;



  
  
  border-radius: 25px;
 
}



@keyframes colorchange {
    0% {
      box-shadow: 10px 10px 350px rgb(198, 215, 71) ;
      background: rgba(198, 215, 71, 0.5) ;
        
       
      }
    25% {
      box-shadow: 20px 20px 700px rgb(121, 194, 103);
      background: rgba(121, 194, 103, 0.5) ;
        
      }
      50% {
        box-shadow: 10px 10px 350px rgb(232, 104, 162) ;
        background: rgba(232, 104, 162, 0.5) ;
        
      }
      75% {
        box-shadow: 20px 20px 700px rgb(192, 98, 167) ;
        background: rgba(192, 98, 167, 0.5) ;
        
      }
      100% {
        box-shadow: 10px 10px 350px rgb(245, 214, 61) ;
        background: rgba(245, 214, 61, 0.5) ;
      }
   
}


@media only screen and (max-width: 480px) {

  .backdrop .Mainimg {
    display: block;
    max-width:100%;
    max-height: 100%;

    margin-top: 1em;
    margin-left:auto;
 
    box-shadow:none;
    
    border-radius: 25px;
    animation: none;
  }
  
  .backdrop{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index:100;
    background-image:none;
    background: rgb(121,194,103);
    background: linear-gradient(0deg, rgba(121,194,103,1) 26%, rgba(198,215,71,1) 49%, rgba(255,255,255,1) 50%, rgba(120,197,214,1) 100%);
     z-index:1001;
  
  }

}


@media (min-width: 481px) and (max-width: 767px) {
  
  .backdrop .Mainimg {
    display: block;
    max-width: 80vw;
    max-height: 60vh;

    margin-top: -4em;
    margin-left:auto;
 
    box-shadow:none;
    
    border-radius: 25px;
    animation: none;
  }
  
  .backdrop{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index:100;
    background-image:none;
    background: rgb(121,194,103);
    background: linear-gradient(0deg, rgba(121,194,103,1) 26%, rgba(198,215,71,1) 49%, rgba(255,255,255,1) 50%, rgba(120,197,214,1) 100%);
     z-index:1001;
  
  }
  
}

