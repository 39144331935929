

.ADMIN-overflow {
    background-color: rgba(255, 255, 255, 0.8);
    max-height: calc(100vh - 1px) !important;
    overflow-y: auto;
    overflow-x:hidden;
    height:100vh;
    width:auto;
    display: grid;
    margin: auto;
    background-color: #ffffff;
    padding:20px;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 25px 0;
   
    

}

.ADMIN-grid-container {
 
  

  }

  .ADMIN-grid-item {

    margin:auto;
    padding: auto;
    font-size: 30px;
    height:auto;
    width:85%;
    margin-left:auto;
    margin-right:auto;
    
  }

.ADMIN-img-grid{


  
width:auto;
height:auto;

    
    

  }
  .ADMIN-img-grid-IMG-Size{
   
    width: 75%;
    height: auto;
    margin:auto;
    cursor:pointer;
    margin-top: 2;
 
    margin-left:4vw;
    
 
  }

  .ADMIN-DELETE-img-grid-IMG-Size{

    width: 100%;
    height: 100%;
    margin:auto;
    cursor:pointer;
  
    
 
  }

  .ADMIN-img-text-Wrapper {

    width: auto;
    overflow-x: hidden;
    margin-top:1em;
    margin-bottom:1em;
   
    

   
  }


  .ADMIN-img-text-right{

 
    font-size: 2vw;
    font-family: 'Luckiest Guy';
    text-align: center;
    
  }

  .canvas-z-index {
  

   
    z-index: 100006;

  }

  @media (min-width: 481px) and (max-width: 767px) {

    
  
  .ADMIN-Delete-Confirmation-pop-up-box {

    position: fixed;
    width: 35vw;
    height: auto;
    top: 50%;
    left: 50%;
    margin-top: -10vh; /* Negative half of height. */
    margin-left: -15vw; /* Negative half of width. */
    background-color:rgb(69, 155, 168); 
    box-shadow: 10px 10px 350px rgb(120, 197, 214);
    z-index: 100001;
    border-radius: 50px;
  }
  
  .ADMIN-Delete-Confirmation-pop-up-box-text {  
    display: block;
    color: white;
    font-family: 'Luckiest Guy';
    text-align: center;
    font-size: 3vw;
    margin-left: 1vw;
    margin-right:1vw;
    margin-top:1vh;
    
  }
  
  .ADMIN-Delete-Confirmation-pop-up-box-buttons {  
    display: block;
    font-family: 'Luckiest Guy';
    text-align: center;
    margin-left: 1vw;
    margin-right:1vw;
    margin-top:4vh;
  
    
    
  }
  
  .ADMIN-Delete-Confirmation-pop-up-box-buttons-colors {  
    font-family: 'Luckiest Guy';
    text-align: center;
    font-size: 3vw;
    text-decoration: none;
    background-color:rgb(120, 197, 214); 
    color: white;
    border: none;
    margin-left: .25em;
    margin-right:.25em;
    margin-top:.12em;
   }
  
  
  
  }
  
  .canvas-z-index {
  
    position: relative;
    margin:auto;
   
    z-index: 100001;

  }
  
  
  .ADMIN-Delete-Confirmation-pop-up-box {
  
    position: fixed;
    width: 35vw;
    height: auto;
    top: 20%;
    left: 50%;
    margin-top: -20vh; /* Negative half of height. */
    margin-left: -18vw; /* Negative half of width. */
    background-color:rgb(69, 155, 168); 
    box-shadow: 10px 10px 350px rgb(120, 197, 214);
    z-index: 100001;
    border-radius: 50px;
  }

  .ADMIN-Delete-Confirmation {
  
    position: fixed;
    width: 100%;
    height: 100%;
   

    background-color:transparent;
   
    z-index: 100001;

  }
  
  .ADMIN-Delete-Confirmation-pop-up-box-text {  
    display: block;
    color: white;
    font-family: 'Luckiest Guy';
    text-align: center;
    font-size: 3vw;
    margin-left: 1vw;
    margin-right:1vw;
    margin-top:4vh;
    
  }
  
  .ADMIN-Delete-Confirmation-pop-up-box-buttons {  
    display: block;
    font-family: 'Luckiest Guy';
    text-align: center;
    margin-left: 1vw;
    margin-right:1vw;
    margin-bottom:1vh;
  
    
    
  }
  
  .ADMIN-Delete-Confirmation-pop-up-box-buttons-colors {  
    font-family: 'Luckiest Guy';
    text-align: center;
    font-size: 3vw;
    text-decoration: none;
    background-color:rgb(120, 197, 214); 
    color: white;
    border: none;
    border-radius: 50px;
    margin-left: .25em;
    margin-right:.25em;
    margin-top:.12em;
   }

   .ADMIN-Delete-Confirmation-buttons-colors {  
    font-family: 'Luckiest Guy';
    text-align: center;
    font-size: 5vw;
    text-decoration: none;
    background-color:rgb(232, 104, 162); 
    color: white;
    border: none;
    border-radius: 50px;
    margin-left:3.5vw;
    margin-top:1em;
    margin-bottom:1em;
    width:25vw;
    cursor: pointer;
   }
   
  
  
   
  
   @media (max-width: 480px) {
  
    .ADMIN-Delete-Confirmation-pop-up-box {
  
      position: fixed;
      width: 70vw;
      height: auto;
      top: 25%;
      left: 50%;
      margin-top: -7vh; /* Negative half of height. */
      margin-left: -35vw; /* Negative half of width. */
      background-color:rgb(69, 155, 168); 
      box-shadow: 10px 10px 350px rgb(120, 197, 214) ;
      z-index: 100001;
      border-radius: 50px;
    }
    
    .ADMIN-Delete-Confirmation-pop-up-box-text {  
      display: block;
      color: white;
      font-family: 'Luckiest Guy';
      text-align: center;
      font-size: 8vw;
      margin-left: 1vw;
      margin-right:1vw;
      margin-top:3vh;
      
    }
    
    .ADMIN-Delete-Confirmation-pop-up-box-buttons {  
      display: block;
      font-family: 'Luckiest Guy';
      text-align: center;
      margin-left: 1vw;
      margin-right:1vw;
      margin-top:.25vh;
      
    
      
      
    }
    
    .ADMIN-Delete-Confirmation-pop-up-box-buttons-colors {  
      font-family: 'Luckiest Guy';
      text-align: center;
      font-size: 8vw;
      text-decoration: none;
      background-color:rgb(120, 197, 214); 
      color: white;
      border: none;
      margin-left: .25em;
      margin-right:.25em;
      margin-top:.12em;
     }
    
    
   
     .canvas-z-index {
  

   
      z-index: 100002;
  
    }
  
  
   }
 
  