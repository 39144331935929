body {
    touch-action: none;

   
}

.home-button{

  display: block;
  margin-bottom:.5vw;
  width:auto;
  height:auto;
}

.PaintBrush-button{
  
  background-color:rgb(121, 194, 103);
}


.PaintBrush-button:focus{background-color:rgb(232, 104, 162);}


.hide {
   
   display: none;
}




.flex-container-DrawingApp {
  display: flex;
}

.flex-child {
  flex: 1;
}

.backgroundToolset{
 
  background: rgb(255,255,255);
  background: linear-gradient(280deg, rgba(255,255,255,1) 16%, rgba(120,197,214,1) 87%, rgba(69,155,168,1) 100%);
 
 
  
}

.toolset-row-1 {
  
  border: none;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1em;
  cursor: pointer;
 

 
}
.toolset-row-2 {
  
  border: none;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1em;
  cursor: pointer;

}
.toolset-row-3 {
  
  border: none;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1em;
  cursor: pointer;

 
}
.toolset-row-4 {
  
  border: none;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1em;
  cursor: pointer;

}
.toolset-row-5 {
  
  border: none;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1em;
  cursor: pointer;

}

button {
  border-radius:25%;

  margin:.25vw;
 
}

#canvas {
  width: 100%;
}



.undo-button-wrapper {
  position: fixed;
  z-index: 100;
  top: 16em;
  left: 3.5em;
  transform: translate(-50%, -50%);
  height:19em;
}

.undo-button {
  align-items: center; 
  cursor: pointer;
  display: flex;
  height: 5rem;
  justify-content: center;
  outline: none;
  user-select: none;
  width: 5rem;
}


.alert {
  position: fixed;
  top: 28vh;
  left: -28em;
  padding: 2em;
  background: rgb(121, 194, 103);
  box-shadow: 5px 10px 18px rgb(121, 194, 103) ;
  color: white;
  border-radius:50%;
  width: auto;
  height: auto;
  transform: translate(-50%, -50%);
  
}







.slider-button-wrapper {
  position: fixed;
  z-index: 100;
  top: 11.25em;
  left: 45em;
  transform: translate(-50%, -50%);
  height:auto;


}
.closebtn {
  
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
}

.closebtn:hover {
  color: rgb(198, 215, 71);
}

.slider {
  
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(121,194,103,1) 100%);
  cursor: pointer;
  border-radius: 50%;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: linear-gradient(333deg, rgba(255,255,255,1) 0%, rgba(120,197,214,1) 81%, rgba(69,155,168,1) 100%);
  cursor: pointer;
}

.alert-orange {
  position: fixed;
  top: 26vh;
  left: -28em;
  padding: 2em;
  background: rgb(242, 140, 51) ;
  box-shadow: 5px 10px 18px rgb(242, 140, 51)  ;
  color: white;
  border-radius:50%;
  width: auto;
  height: auto;
  transform: translate(-50%, -50%);
  
}

.closebtn-orange {
  
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
}

.closebtn-orange:hover {
  color: rgb(245, 214, 61);
}

.slider-orange {
  
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.slider-orange:hover {
  opacity: 1;
}

.slider-orange::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgb(242, 140, 51) 100%);
  cursor: pointer;
  border-radius: 50%;
}

.slider-orange::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: linear-gradient(333deg, rgba(255,255,255,1) 0%, rgba(120,197,214,1) 81%, rgba(69,155,168,1) 100%);
  cursor: pointer;
}

.alert-lime {
  position: fixed;
  top: 26vh;
  left: -28em;
  padding: 2em;
  background: rgb(198, 215, 71) ;
  box-shadow: 5px 10px 18px rgb(198, 215, 71) ;
  color: white;
  border-radius:50%;
  width: auto;
  height: auto;
  transform: translate(-50%, -50%);
  
}


.closebtn-lime {
  
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
}

.closebtn-lime:hover {
  color: rgb(121, 194, 103);
}

.slider-lime {
  
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.slider-lime:hover {
  opacity: 1;
}

.slider-lime::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgb(198, 215, 71) 100%);
  cursor: pointer;
  border-radius: 50%;
}

.slider-lime::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: linear-gradient(333deg, rgba(255,255,255,1) 0%, rgba(120,197,214,1) 81%, rgba(69,155,168,1) 100%);
  cursor: pointer;
}

.alert-blue {
  position: fixed;
  top: 29vh;
  left: -28em;
  padding: 2em;
  background:rgb(69, 155, 168) ;
  box-shadow: 5px 10px 18px rgb(69, 155, 168) ;
  color: white;
  border-radius:50%;
  width: auto;
  height: auto;
  transform: translate(-50%, -50%);
  
}


.closebtn-blue {
  
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
}

.closebtn-blue:hover {
  color: rgb(120, 197, 214) ;
}

.slider-blue {
  
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.slider-blue:hover {
  opacity: 1;
}

.slider-blue::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgb(69, 155, 168) 100%);
  cursor: pointer;
  border-radius: 50%;
}

.slider-blue::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: linear-gradient(333deg, rgba(255,255,255,1) 0%, rgba(120,197,214,1) 81%, rgba(69,155,168,1) 100%);
  cursor: pointer;
}

.alert-purple {
  position: fixed;
  top: 26vh;
  left: -28em;
  padding: 2em;
  background:rgb(192, 98, 167);
  box-shadow: 5px 10px 18px rgb(192, 98, 167);
  color: white;
  border-radius:50%;
  width: auto;
  height: auto;
  transform: translate(-50%, -50%);
  
}


.closebtn-purple {
  
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
}

.closebtn-purple:hover {
  color:rgb(232, 104, 162) ;
}

.slider-purple {
  
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.slider-purple:hover {
  opacity: 1;
}

.slider-purple::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgb(192, 98, 167) 100%);
  cursor: pointer;
  border-radius: 50%;
}

.slider-purple::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: linear-gradient(333deg, rgba(255,255,255,1) 0%, rgba(120,197,214,1) 81%, rgba(69,155,168,1) 100%);
  cursor: pointer;
}

.tooltip {
  position: relative;
  display: inline-block;
  
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: auto;
 
  font-size: 1vw;
  background-color: rgb(232, 104, 162) ;
  color: white;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}



.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip:active .tooltiptext {
  visibility: hidden;
}


.Bottom-row-tooltip {
  position: relative;
  display: inline-block;
  
}

.Bottom-row-tooltip .Bottom-row-tooltiptext {
  visibility: hidden;
  width: auto;
  font-size: 1vw;
  background-color: rgb(232, 104, 162) ;
  color: white;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  top: 1px;
  left: 50px;
  z-index: 1;
}

.Bottom-row-tooltip:hover .Bottom-row-tooltiptext {
  visibility: visible;
}

.Bottom-row-tooltip:active .Bottom-row-tooltiptext {
  visibility: hidden;
}

.Right-tooltip {
  position: relative;
  display: inline-block;
  
}

.Right-tooltip .Right-tooltiptext {
  visibility: hidden;
  width: auto;
  left: -6vw;
  font-size: 1vw;
  background-color: rgb(232, 104, 162) ;
  color: white;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.Right-tooltip:hover .Right-tooltiptext {
  visibility: visible;
}

.Right-tooltip:active .Right-tooltiptext {
  visibility: hidden;
}

.Bottom-Right-row-tooltip {
  position: relative;
  display: inline-block;
  
}



.Bottom-Right-row-tooltip .Bottom-Right-row-tooltiptext {
  visibility: hidden;
  width: auto;
  left: -6vw;
  font-size: 1vw;
  background-color: rgb(232, 104, 162) ;
  color: white;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
 

  /* Position the tooltip */
  position: absolute;
  top: 10px;
 
  z-index: 1;
}

.Bottom-Right-row-tooltip1 {
  position: relative;
  display: inline-block;
  
}
.Bottom-Right-row-tooltip1 .Bottom-Right-row-tooltiptext1 {
  visibility: hidden;
  width: auto;
  left: 3vw;
   font-size: 1vw;
  background-color: rgb(232, 104, 162) ;
  color: white;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  top: 10px;
 
  z-index: 1;
}
.Bottom-Right-row-tooltip:hover .Bottom-Right-row-tooltiptext {
  visibility: visible;
}

.Bottom-Right-row-tooltip:active .Bottom-Right-row-tooltiptext {
  visibility: hidden;
}

.Bottom-Right-row-tooltip1:hover .Bottom-Right-row-tooltiptext1 {
  visibility: visible;
}

.Bottom-Right-row-tooltip1:active .Bottom-Right-row-tooltiptext1 {
  visibility: hidden;
}



.GoHome-pop-up-box {

  position: fixed;
  width: 38vw;
  height: auto;
  top: 12vh;
  left: 50vw;
  margin-top: -1vh; /* Negative half of height. */
  margin-left: -15vw; /* Negative half of width. */
  background-color:rgb(245, 214, 61); 
  box-shadow: 10px 10px 350px rgb(120, 197, 214) ;
  z-index: 100001;
  border-radius: 50px;
}

.GoHome-pop-up-box-text {  
  display: block;
  color: white;
  font-family: 'Luckiest Guy';
  text-align: center;
  font-size: 5vw;
  margin-left: 1vw;
  margin-right:1vw;
  margin-top:1vh;
  
}

.GoHome-pop-up-box-buttons {  
  display: block;
  font-family: 'Luckiest Guy';
  text-align: center;
  margin-left: 1vw;
  margin-right:1vw;
  margin-top:1vh;
  margin-bottom: 5vh;

  
  
}

.GoHome-pop-up-box-buttons-colors {  
  font-family: 'Luckiest Guy';
  text-align: center;
  font-size: 3vw;
  text-decoration: none;
  background-color:rgb(120, 197, 214) ;
  color: white;
  border: none;
  margin-left: .25em;
  margin-right:.25em;
  margin-top:.4em;
 }




@media (min-width: 320px) and (max-width: 480px) {

  .Bottom-Right-row-tooltip:hover .Bottom-Right-row-tooltiptext {
    visibility: hidden;
  }

  .Bottom-Right-row-tooltip1:hover .Bottom-Right-row-tooltiptext1 {
    visibility: hidden;
  }

  .Right-tooltip:hover .Right-tooltiptext {
    visibility: hidden;
  }
  .tooltip:hover .tooltiptext {
    visibility: hidden;
  }
  .Bottom-row-tooltip:hover .Bottom-row-tooltiptext {
    visibility: hidden;
  }
}
@media (min-width: 481px) and (max-width: 767px) {
  
  .Bottom-Right-row-tooltip:hover .Bottom-Right-row-tooltiptext {
    visibility: hidden;
  }

  .Bottom-Right-row-tooltip1:hover .Bottom-Right-row-tooltiptext1 {
    visibility: hidden;
  }

  .Right-tooltip:hover .Right-tooltiptext {
    visibility: hidden;
  }
  .tooltip:hover .tooltiptext {
    visibility: hidden;
  }
  .Bottom-row-tooltip:hover .Bottom-row-tooltiptext {
    visibility: hidden;
  }

 
}
